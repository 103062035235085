











































import { Component, Prop, Vue } from 'vue-property-decorator'

import CircleCheckIcon from './partials/CircleCheckIcon.vue'
import { ProductReviewProps } from './ProductReview.contracts'

/**
 * @author Filip Rurak <filip.rurak@movecloser.pl>
 */
@Component<ProductReview>({
  name: 'ProductReview',
  components: {
    CircleCheckIcon
  }
})
export class ProductReview extends Vue implements ProductReviewProps {
  @Prop({ type: String, required: true, default: '' })
  author!: ProductReviewProps['author']

  @Prop({ type: String, required: false, default: '' })
  color!: ProductReviewProps['color']

  @Prop({ type: String, required: false, default: '' })
  content!: ProductReviewProps['content']

  @Prop({ type: String, required: true, default: '' })
  createdAt!: ProductReviewProps['createdAt']

  @Prop({ type: String, required: true, default: '' })
  desc!: ProductReviewProps['desc']

  @Prop({ type: Boolean, required: true, default: false })
  isVerified!: ProductReviewProps['isVerified']

  @Prop({ type: String, required: true, default: '/' })
  location!: ProductReviewProps['location']

  @Prop({ type: Number, required: true, default: 5 })
  rate!: ProductReviewProps['rate']

  @Prop({ type: String, required: true, default: '' })
  title!: ProductReviewProps['title']

  /**
   * Formats to DD.MM.YYYY from createdAt string 'YYYY-MM-DD ..."
   */
  public get formatterCreatedAt () {
    return `${this.createdAt.slice(8, 10)}.${this.createdAt.slice(5, 7)}.${this.createdAt.slice(0, 4)}`
  }
}

export default ProductReview
