






























import { Component, Mixins } from 'vue-property-decorator'
import { EventbusType, IEventbus } from '@movecloser/front-core'

import { Inject } from '../../../../support/plugins/front-core'

import { AbstractDrawer } from '../../../shared/organisms/AbstractDrawer'
import {
  IProductsRepository,
  ProductsRepositoryType
} from '../../../products/contracts/repositories'
import { IToastMixin, ToastMixin } from '../../../shared'
import { CartAnalyticsMixin } from '../../../shared/mixins/cart-analytics.mixin'

import { BaseCartMixin, IBaseCart } from '../../shared/mixins/base-cart.mixin'
import { translateToCartItem } from '../../molecules/CartItem/CartItem.helpers'
import { RouteName } from '../../routes'

import { SingleCartItem } from '../SingleCartItem'

/**
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 */
@Component<CartDrawer>({
  name: 'CartDrawer',
  components: { SingleCartItem }
})
export class CartDrawer extends Mixins<IBaseCart, AbstractDrawer, IToastMixin, CartAnalyticsMixin>(
  BaseCartMixin,
  AbstractDrawer,
  ToastMixin,
  CartAnalyticsMixin
) {
  @Inject(ProductsRepositoryType)
  protected readonly productsRepository!: IProductsRepository

  @Inject(EventbusType)
  protected readonly eventBus!: IEventbus

  public isLoading: boolean = true

  public async goToCart (): Promise<void> {
    if (this.$route.name === `checkout.${RouteName.Cart}`) {
      this.close()
      return
    }

    await this.$router.push({ name: `checkout.${RouteName.Cart}` })
    this.close()
  }

  /**
   * Handles quantity changing of particular product.
   */
  public async onQuantityChange (uid: string, newQuantity: number): Promise<void> {
    try {
      const updatedCart = await this.cartService.updateCartItem(this.cartId, uid, newQuantity)

      this.eventBus.emit('app:cart.view', this.getCartViewPayload(updatedCart))

      this.refreshCart(updatedCart)
    } catch (e) {
      this.showToast((e as Error).message, 'danger')
    }
  }

  /**
   * Handles @removeFromCartCb of SingleCartItem organism.
   */
  public async removeFromCart (uid: string): Promise<void> {
    try {
      const itemToRemove = this.cart?.items.find(item => {
        return item.uid === uid
      })

      const updatedCart = await this.cartService.removeFromCart(this.cartId, uid)

      if (itemToRemove) {
        this.eventBus.emit('app:cart.remove', this.getCartRemovePayload(itemToRemove, updatedCart))
      }

      this.refreshCart(updatedCart)
    } catch (e) {
      this.showToast((e as Error).message, 'danger')
    }
  }

  public translateToCartItem = translateToCartItem
}

export default CartDrawer
