




























import { Component, Mixins, Prop, Vue, Watch } from 'vue-property-decorator'
import { FiltersConfig } from '@movecloser/front-core/lib/contracts/filter-parser'

import { FilterParamConfig } from '../../../../contexts'
import { StructureConfigurable } from '../../../../support/mixins'

import { FilterParamBadge } from './FilterBadges.contracts'

import {
  FILTER_BADGES_COMPONENT_CONFIG_MAP,
  FILTER_BADGES_COMPONENT_KEY,
  FilterBadgesConfig
} from './FilterBadges.config'
import { UNAVAILABLE_IDENTIFIER } from '../FilterList/FilterList.config'

/**
 * @author Agnieszka Zawadzka <agnieszka.zawadzka@movecloser.pl>
 */
@Component<FilterBadges>({
  name: 'FilterBadges',
  created () {
    this.config = this.getComponentConfig(FILTER_BADGES_COMPONENT_KEY, { ...FILTER_BADGES_COMPONENT_CONFIG_MAP })
  },
  mounted () {
    this.prepareBadges()
  }
})
export class FilterBadges extends Mixins(Vue, StructureConfigurable) {
  @Prop({ required: false, type: String })
  public emptyMessage?: string

  @Prop({ required: true, type: Object })
  public filters!: FiltersConfig

  @Prop({ required: true, type: Array })
  public params!: FilterParamConfig[]

  @Prop({ required: false, type: Boolean, default: true })
  public shouldRenderEmptyState?: boolean

  protected config!: FilterBadgesConfig
  public badges: FilterParamBadge[] = []

  public get clearWhenEmpty (): boolean {
    return this.getConfigProperty('clearWhenEmpty')
  }

  public get hasClearAllIcon (): boolean {
    return this.getConfigProperty<boolean>('hasClearAllIcon')
  }

  public get closeIcon (): string {
    return this.getConfigProperty<string>('closeIcon')
  }

  public get hasBadges () {
    return this.badges && this.badges.length
  }

  public get savedFilterParams () {
    return this.$store.getters['shared/getFilterParams']
  }

  public clearFilters () {
    this.$emit('clearFilters')
  }

  public removeFilter (param: string, value: string | number | boolean) {
    this.$emit('removeFilter', param, value)
  }

  private getBadge (param: string, value: string): FilterParamBadge | null {
    const params = this.$store.getters['shared/getFilterParams'] as Array<FilterParamConfig> ?? this.params
    const paramConfig = params.find(p => p.queryParam === param)

    if (!paramConfig) {
      return null
    }

    return { queryParam: param, value, label: paramConfig.options[value] }
  }

  private maybePushBadge (badges:FilterParamBadge[], param: string, value: string) {
    const badge = this.getBadge(param, value)
    if (badge) {
      badges.push(badge)
    }
  }

  @Watch('savedFilterParams')
  protected onProductsListFilterParamsUpdate () {
    this.badges = this.composeBadges()
    if (this.clearWhenEmpty) {
      this.forceBadgesClear(this.badges)
    }
  }

  @Watch('filters')
  private prepareBadges () {
    if (!this.filters) {
      this.badges = []
      return
    }

    this.badges = this.composeBadges()

    if (this.clearWhenEmpty) {
      // FIXME: This should not be fixed.
      // ...API resolves filters name with '(0) always :/
      this.forceBadgesClear(this.badges)
    }
  }

  private composeBadges (): Array<FilterParamBadge> {
    const badges: FilterParamBadge[] = []

    Object.entries(this.filters).forEach(([param, value]) => {
      if (Array.isArray(value)) {
        value.forEach(v => {
          this.maybePushBadge(badges, param, v.value.toString())
        })
      } else {
        if (value !== '') {
          this.maybePushBadge(badges, param, value.toString())
        }
      }
    })

    return badges
  }

  private forceBadgesClear (badges: Array<FilterParamBadge>): void {
    const clearFilters = this.badges.every((badge) => badge.label.includes(UNAVAILABLE_IDENTIFIER))

    if (this.badges.length > 0 && clearFilters) {
      this.clearFilters()
    }
  }
}

export default FilterBadges
