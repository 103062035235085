
/**
 * @author Filip Rurak <filip.rurak@movecloser.pl>
 */
export const FILTER_LIST_KEY = 'FilterListComponent'

/**
 * @author Filip Rurak <filip.rurak@movecloser.pl>
 */
export interface FilterListConfig {
  disableUnavailableFilters: boolean
  sortUnavailable: boolean
}

/**
 * @author Filip Rurak <filip.rurak@movecloser.pl>
 */
export const FILTER_LIST_CONFIG_MAP: FilterListConfig = {
  disableUnavailableFilters: false,
  sortUnavailable: false
}

/**
 * @author Filip Rurak <filip.rurak@movecloser.pl>
 * FIXME: This should not be fixed.
 * FIXME: ...API resolves filters name with '(0) always :/
 */
export const UNAVAILABLE_IDENTIFIER = '(0)'
